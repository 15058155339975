<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit {{ isContact ? "Contact" : "User" }}</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Update</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <ValidationObserver ref="editContactUser" v-slot="{ handleSubmit }">
          <form id="editContactUser" @submit.prevent="handleSubmit(onSubmit)">
            <CRow>
              <CCol lg="6" v-if="isContact">
                <CRow name="upgradeUser" class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Upgrade to user</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="upgradeUser"
                        :value="user.upgradeUser"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6" v-if="!isContact">
                <CRow name="customer_user_type" class="mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Select the type of User</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider v-slot="{ errors }">
                      <Select
                        name="customer_user_type_ids"
                        :value="user.customer_user_type_ids"
                        :options="
                          options && options['user_type']
                            ? options['user_type']
                            : []
                        "
                        :multiple="true"
                        :clearable="false"
                        @input="handleChangeMultiSelectAsArray"
                        :error="errors[0]"
                        :disabled="isAdvertiser"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="customer_user_type" class="mb-3">
                  <label class="col-lg-12 col-md-12 required">Job Title / Level</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="contact_type_id"
                        :value="user.contact_type_id"
                        :options="
                          options && options['contact_type']
                            ? options['contact_type']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="department" class="mb-3">
                  <label class="col-lg-12 col-md-12">Department</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                      <Select
                        name="department_id"
                        :value="user.department_id"
                        :options="
                          options && options['department']
                            ? options['department']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                      />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="first_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">First Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_name"
                        :value="user.first_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="last_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Last Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="last_name"
                        :value="user.last_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="phone" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Phone</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="user.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        :error="errors[0]"
                        @change="handleInput"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="email" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="email"
                        :value="user.email"
                        :error="errors[0]"
                        @input="handleInput"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="isUpgradeToUser">
              <CCol md="6">
                <CRow name="password" class="mb-3">
                  <label class="col-lg-12 col-md-12 required">Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="password"
                      :rules="{
                        required: true,
                        password_length: 8,
                        password_strength:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="password"
                        :value="user.password"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="new-password"
                      />
                      <small class="hint">
                        Password format: 8 characters - containing upper & lower
                        case letters, numbers and a special character. No words.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="confirm_password" class="row mb-3">
                  <label class="col-lg-12 col-md-12">Confirm Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                      ref="confirmPassword"
                    >
                      <PasswordInput
                        name="confirmPassword"
                        :value="confirmPassword"
                        @input="handleConfirmPassword"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

import { extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  confirmed,
  regex,
  min,
} from "vee-validate/dist/rules";
import { isEmptyObjectCheck, isObject } from "../../helpers/helper";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
    RadioButton,
  },
  data() {
    return {
      user: {},
      payload: {},
      customerRecruiterOpt: { code: 0, label: "Customer Recruiter" },
      confirmPassword: null,
    };
  },
  computed: {
    ...mapGetters([
      "userTypes",
      "dialingCodeWithCountryCode",
      "contactType",
      "isFetchingFilter",
      "getDepartmentsByOrganisationId",
      "isCandidateSupplierFromAccessToken",
      "isJobSupplierFromAccessToken"
    ]),
    options() {
      return {
        user_type: this.isJobSupplierFromAccessToken
          ? this.partnerHospitalUserType
          : this.isCandidateSupplierFromAccessToken 
            ? this.partnerAgencyUserType
            : this.customUserType || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        contact_type: this.contactType || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        department: this.getDepartmentsByOrganisationId || [],
      };
    },
    customUserType() {
      let userTypes = [...this.userTypes];
      if (
        !(
          this.userDetails?.user?.roles &&
          this.userDetails?.user?.roles.some(
            (v) => v.role_type === "customer_admin"
          )
        )
      )
        userTypes = [...userTypes, this.customerRecruiterOpt];
      return this.user.customer_user_type_ids && userTypes && userTypes.length
        ? userTypes.filter(
            (val) =>
              !this.user.customer_user_type_ids.some(
                ({ code }) => code === val?.code
              )
          )
        : userTypes;
    },
    partnerHospitalUserType() {
      return this.customUserType ? _(this.customUserType).filter((val) => val.code != 4).value() : []   // Remove job approver for Job Suppliers / Partner Hospitals
    },
    partnerAgencyUserType() {
      return [this.customerRecruiterOpt] || []  // List only recruiters for Candidate Suppliers / Partner Agencies
    },
    isContact() {
      return (
        !isObject(this.userDetails?.user) ||
        isEmptyObjectCheck(this.userDetails?.user)
      );
    },
    isUpgradeToUser() {
      return (this.isContact && this.payload?.upgradeUser) || false;
    },
    isAdvertiser() {
      return this.user?.customer_user_type_ids?.some(
        (item) => item.label === "Job Advertiser"
      );
    },
  },
  methods: {
    ...mapActions(["initContactFetch", "showToast"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.user, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    handleChangeSelect(name, value) {
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
      };
    },
    handleChangeMultiSelectAsArray(name, value) {
      // const newInput =
      //   value.filter(
      //     (v) => !this.user[name].some((val) => val?.code === v?.code)
      //   ) || [];

      // const alreadyRecruiter = this.user[name].some((v) => v?.code === 0);
      // const val = newInput.length
      //   ? newInput[0].code === 0
      //     ? [this.customerRecruiterOpt]
      //     : alreadyRecruiter
      //     ? newInput
      //     : value
      //   : value;
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code;
            })
          : null,
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.editContactUser.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        contact_id: this.user?.contact_id,
        user_id: this.user?.user_id,
        contact_type_id: this.user?.contact_type_id?.code,
        first_name: this.user?.first_name,
        last_name: this.user?.last_name,
        phone: this.user?.phone,
        department_id: this.user?.department_id?.code,
      };
      if (!this.isContact) {
        finalPayload = {
          ...finalPayload,
          customer_users: this.user?.customer_user_type_ids
            ? this.user?.customer_user_type_ids
                .filter(({ code }) => code)
                .map(({ code }) => ({
                  user_id: this.user?.user_id,
                  customer_user_type_id: code,
                  created_by: this.user?.created_by,
                })) || []
            : [],
          customer_recruiters: this.user?.customer_user_type_ids
            ? this.user?.customer_user_type_ids
                .filter(({ code }) => code === this.customerRecruiterOpt?.code)
                .map((v) => ({
                  user_id: this.user?.user_id,
                  recruitment_type_id: 1,
                  created_by: this.user?.created_by,
                })) || []
            : [],
        };
      }
      if (this.isUpgradeToUser) {
        finalPayload = { ...finalPayload, password: this.payload?.password };
      }
      this.modalCallBack(true, finalPayload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    getName(data) {
      if (data?.user) {
        return {
          first_name: data?.user?.first_name,
          surname: data?.user?.surname,
        };
      }
      const nameSplit = data?.name.split(" ");
      return {
        first_name: nameSplit[0] || "",
        surname: nameSplit.slice(1).join(""),
      };
    },
  },
  mounted() {
    const userData = this.userDetails;
    const names = this.getName(userData);
    this.user = {
      customer_user_type_ids: userData?.user?.users_roles
        ? userData?.user?.users_roles.map((val) => ({
            code: val?.id,
            label: val?.display_name,
          }))
        : [],
      contact_type_id: userData?.contact_type
        ? {
            code: userData?.contact_type?.contact_type_id,
            label: userData?.contact_type?.contact_type,
          }
        : null,
      first_name: names?.first_name || null,
      last_name: names?.surname || null,
      phone: userData?.phone_no || null,
      email: userData?.email || null,
      user_id: userData?.user?.id || null,
      customer_user_id: userData?.user?.id || null,
      contact_id: userData?.contact_id,
      created_by: userData?.created_by,
      department_id: userData?.department?.department_id
        ? {
            code: userData?.department?.department_id,
            label: userData?.department?.department_name,
          }
        : null,
    };
    userData?.user?.recruiters_roles && userData?.user?.recruiters_roles.length
      ? this.user?.customer_user_type_ids.push(this.customerRecruiterOpt)
      : null;
    this.initContactFetch();
    if (this.isContact) {
      this.handleChangeRadio("upgradeUser", false);
    }
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{attrs:{"color":"primary","show":_vm.isShowPopup,"close-on-backdrop":false,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("Edit "+_vm._s(_vm.isContact ? "Contact" : "User"))]),_c('CButtonClose',{staticClass:"text-black",on:{"click":function($event){return _vm.modalCallBack(false)}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary","variant":"outline"},on:{"click":function($event){return _vm.modalCallBack(false)}}},[_vm._v("Cancel")]),_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Update")])]},proxy:true}])},[(_vm.isFetchingFilter)?_c('LoadingOverlay'):_c('div',[_c('ValidationObserver',{ref:"editContactUser",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"id":"editContactUser"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',[(_vm.isContact)?_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"upgradeUser"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Upgrade to user")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('RadioButton',{attrs:{"name":"upgradeUser","value":_vm.user.upgradeUser,"options":_vm.options && _vm.options['boolean']
                          ? _vm.options['boolean']
                          : [],"error":errors[0]},on:{"change":_vm.handleChangeRadio}})]}}],null,true)})],1)])],1):_vm._e()],1),_c('CRow',[(!_vm.isContact)?_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"customer_user_type"}},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Select the type of User")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"customer_user_type_ids","value":_vm.user.customer_user_type_ids,"options":_vm.options && _vm.options['user_type']
                          ? _vm.options['user_type']
                          : [],"multiple":true,"clearable":false,"error":errors[0],"disabled":_vm.isAdvertiser},on:{"input":_vm.handleChangeMultiSelectAsArray}})]}}],null,true)})],1)])],1):_vm._e(),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"customer_user_type"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Job Title / Level")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"contact_type_id","value":_vm.user.contact_type_id,"options":_vm.options && _vm.options['contact_type']
                          ? _vm.options['contact_type']
                          : [],"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"department"}},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Department")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('Select',{attrs:{"name":"department_id","value":_vm.user.department_id,"options":_vm.options && _vm.options['department']
                          ? _vm.options['department']
                          : [],"multiple":false,"clearable":false},on:{"input":_vm.handleChangeSelect}})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"first_name"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("First Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"first_name","value":_vm.user.first_name,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"last_name"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Last Name")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"last_name","value":_vm.user.last_name,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"phone"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Phone")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{ref:"phone",attrs:{"rules":{
                      required: true,
                      phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PhoneInput',{ref:"phoneNo",attrs:{"name":"phone","value":_vm.user.phone,"options":_vm.options && _vm.options['dialingCode']
                          ? _vm.options['dialingCode']
                          : [],"error":errors[0]},on:{"change":_vm.handleInput}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"email"}},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Email")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"email","value":_vm.user.email,"error":errors[0],"disabled":true},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1)],1),(_vm.isUpgradeToUser)?_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-3",attrs:{"name":"password"}},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Password")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"name":"password","rules":{
                      required: true,
                      password_length: 8,
                      password_strength:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PasswordInput',{attrs:{"name":"password","value":_vm.user.password,"error":errors[0],"autocomplete":"new-password"},on:{"input":_vm.handleInput}}),_c('small',{staticClass:"hint"},[_vm._v(" Password format: 8 characters - containing upper & lower case letters, numbers and a special character. No words. ")])]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-3",attrs:{"name":"confirm_password"}},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Confirm Password")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{ref:"confirmPassword",attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('PasswordInput',{attrs:{"name":"confirmPassword","value":_vm.confirmPassword,"error":errors[0]},on:{"input":_vm.handleConfirmPassword}})]}}],null,true)})],1)])],1)],1):_vm._e()],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }